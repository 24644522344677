<template>
  <b-form>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="12"
          ><h6 class="mb-1">
            Müşteri Bilgileri
          </h6></b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
          >
            <b-form-group label="Müşteri Kartı">
              <v-select
                v-model="customer"
                label="name"
                :filterable="false"
                :options="customers"
                placeholder="Müşteri Kartı"
                @search="onCustomersSearch"
              >
                <template slot="no-options">
                  Firma Adı, İlgili Kişi, Telefon, Müşteri Kodu...
                </template>
                <template
                  slot="option"
                  slot-scope="customer"
                >
                  <div
                    v-if="customer.company"
                  >
                    <span class="ml-1">{{ customer.company }}</span>
                  </div>
                </template>
                <template
                  slot="selected-option"
                  slot-scope="customer"
                >
                  <div class="selected">
                    {{ customer.company }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="customer.id"
            cols="12"
          >
            <b-form-group label="Yetkili">
              <v-select
                v-model="formData.id_customer_users"
                label="title"
                :options="users"
                :reduce="user => user.id"
                placeholder="Seçiniz"
              >
                <template
                  slot="option"
                  slot-scope="user"
                >
                  <div class="ml-1">
                    {{ user.title }}
                  </div>
                  <div class="ml-1 text-muted font-weight-bold">
                    {{ user.user_type }}
                  </div>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
          <b-col
            v-if="customer.id"
            cols="12"
          >
            <hr class="mb-2">
            <b-row>
              <b-col
                v-for="(address,key) in addresses"
                :key="key"
                xs="12"
                md="4"
                class="mb-2"
              >
                <b-form-radio
                  v-model="formData.id_customer_address"
                  name="address"
                  :value="address.id"
                >
                  <div class="font-weight-bold">
                    {{ address.title }}
                  </div>
                  <div>{{ address.address }}</div>
                  <div>{{ address.city }} {{ address.district }}</div>
                </b-form-radio>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-card v-if="customer.id">
      <b-card-text>
        <b-row>
          <b-col
            sm="12"
            md="12"
          >
            <h6 class="mb-1">
              Makine Bilgileri
            </h6>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            xs="12"
            md="4"
          >
            <b-form-group label="Proje Numarası">
              <b-form-input
                v-model="formData.project"
                placeholder="Proje Numarası"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="4"
          >
            <b-form-group label="Fabrika / Seri No">
              <b-form-input
                v-model="formData.serial"
                placeholder="Fabrika / Seri No"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="4"
          >
            <b-form-group label="Kurulum Yılı">
              <cleave
                v-model="formData.syear"
                class="form-control"
                :raw="true"
                :options="options.year"
                placeholder="Kurulum Yılı"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group label="Makine Tipi">
              <v-select
                v-model="formData.id_machine_types"
                label="title"
                :options="machineTypes"
                :reduce="machineType => machineType.id"
                placeholder="Seçiniz"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group label="Makine Modeli">
              <v-select
                v-model="formData.id_machine_models"
                label="title"
                :options="machineModels"
                :reduce="machineModel => machineModel.id"
                placeholder="Seçiniz"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group label="Temizleme Teknolojisi">
              <v-select
                v-model="formData.id_machine_cleaning"
                label="title"
                :options="machineCleanings"
                :reduce="machineCleaning => machineCleaning.id"
                placeholder="Seçiniz"
              />
            </b-form-group>
          </b-col>
          <b-col
            xs="12"
            md="3"
          >
            <b-form-group label="Makine Durumu">
              <v-select
                v-model="formData.id_machine_statuses"
                label="title"
                :options="machineStatuses"
                :reduce="machineStatus => machineStatus.id"
                placeholder="Seçiniz"
              />
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group label="Notlar">
              <b-form-textarea
                v-model="formData.notes"
                placeholder="Notlar"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>
    <b-button
      v-if="customer.id"
      variant="danger"
      :disabled="submitStatus"
      @click="submitForm"
    >
      Kaydet
    </b-button>
  </b-form>
</template>

<script>
import {
  BForm,
  BCard,
  BCardText,
  BFormInput,
  BFormTextarea,
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
// eslint-disable-next-line import/no-extraneous-dependencies
import 'cleave.js/dist/addons/cleave-phone.tr'
import _ from 'lodash'
import axiosIns from '@/libs/axios'

export default {
  name: 'MachineAdd',
  components: {
    BForm,
    BCard,
    BCardText,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    vSelect,
    BRow,
    BCol,
    BButton,
    Cleave,
    BFormRadio,
  },
  data() {
    return {
      submitStatus: false,
      customers: [],
      customer: {},
      options: {
        year: {
          blocks: [4],
          uppercase: true,
        },
      },
      formData: {
        project: null,
        serial: null,
        syear: null,
        status: null,
        notes: null,
        id_machine_types: null,
        id_machine_models: null,
        id_machine_cleaning: null,
        id_machine_statuses: null,
        id_customers: null,
        id_customer_users: null,
        id_customer_address: null,
      },
    }
  },
  computed: {
    addresses() {
      return this.$store.getters['customerAddress/getCustomerAddresses']
    },
    users() {
      return this.$store.getters['customerUsers/getCustomerUsers']
    },
    districts() {
      if (this.id_cities.id) {
        return this.$store.getters['districts/getDistricts']
      }
      return []
    },
    machineTypes() {
      return this.$store.getters['machineTypes/getMachine_types']
    },
    machineModels() {
      return this.$store.getters['machineModels/getMachine_models']
    },
    machineCleanings() {
      return this.$store.getters['machineCleaning/getMachine_cleanings']
    },
    machineStatuses() {
      return this.$store.getters['machineStatuses/getMachine_statuses']
    },
    saveStatus() {
      const status = this.$store.getters['machines/getMachineSaveStatus']
      return status
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          project: null,
          serial: null,
          syear: null,
          status: null,
          notes: null,
          id_machine_types: null,
          id_machine_models: null,
          id_machine_cleaning: null,
          id_machine_statuses: null,
          id_customers: null,
          id_customer_users: null,
          id_customer_address: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
    customer(val) {
      if (val.id) {
        this.getCustomerUsers()
        this.getCustomerAddresses()
        this.formData.id_customers = val.id
      }
    },
  },
  created() {
    this.getMachineTypes()
    this.getMachineModels()
    this.getMachineCleanings()
    this.getMachineStatuses()
  },
  methods: {
    /* Customer Search Start --> */
    onCustomersSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.customerSearch(loading, search, this)
      }
    },
    customerSearch: _.debounce((loading, search, vm) => {
      const data = JSON.stringify({
        select: [
          'customers.id AS id',
          'customers.company AS company',
          'customers.phone AS phone',
          'customers.address AS address',
          'cities.title AS city',
          'districts.title AS district',
        ],
        or_like: {
          'customers.company': search,
          'customers.phone': search,
        },
      })
      axiosIns
        .post('/customers', data)
        .then(response => {
          // eslint-disable-next-line no-param-reassign
          vm.customers = response.data.data
          loading(false)
        })
        .catch(error => console.error(error))
    }, 350),
    /* <-- Customer Search End */
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    getMachineTypes() {
      this.$store.dispatch('machineTypes/machine_typesList', {
        select: [
          'machine_types.id AS id',
          'machine_types.title AS title',
        ],
      })
    },
    getMachineModels() {
      this.$store.dispatch('machineModels/machine_modelsList', {
        select: [
          'machine_models.id AS id',
          'machine_models.title AS title',
        ],
      })
    },
    getMachineCleanings() {
      this.$store.dispatch('machineCleaning/machine_cleaningsList', {
        select: [
          'machine_cleaning.id AS id',
          'machine_cleaning.title AS title',
        ],
      })
    },
    getMachineStatuses() {
      this.$store.dispatch('machineStatuses/machine_statusesList', {
        select: [
          'machine_statuses.id AS id',
          'machine_statuses.title AS title',
        ],
      })
    },
    getCustomerAddresses() {
      const params = {
        select: [
          'customer_address.id AS id',
          'customer_address.title AS title',
          'customer_address.address AS address',
          'cities.title AS city',
          'districts.title AS district',
        ],
        where: {
          'customer_address.id_customers': this.customer.id,
        },
      }
      this.$store.dispatch('customerAddress/customerAddressesList', params)
    },
    getCustomerUsers() {
      const params = {
        select: [
          'customer_users.id AS id',
          'customer_users.name AS title',
          'customer_user_types.title AS user_type',
        ],
        where: {
          'customer_users.id_customers': this.customer.id,
          'customer_users.status': 1,
        },
      }
      this.$store.dispatch('customerUsers/customerUsersList', params)
    },
    submitForm() {
      this.submitStatus = true
      this.$store.dispatch('machines/machineSave', this.formData)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
